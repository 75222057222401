body {
  display: inherit;
  margin: inherit;
}

/** Font face imports */
@font-face {
  font-family: "Sofia Pro";
  src: url("../../fonts/SofiaPro-Light.woff2") format("woff2"), url("../../fonts/SofiaPro-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../fonts/SofiaPro-SemiBold.woff2") format("woff2"), url("../../fonts/SofiaPro-SemiBold.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../fonts/SofiaPro-Regular.woff2") format("woff2"), url("../../fonts/SofiaPro-Regular.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../fonts/SofiaPro-Bold.woff2") format("woff2"), url("../../fonts/SofiaPro-Bold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}